import * as React from "react"

const PlayerIconActive = (props) => (
  <svg
    width={23}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.45 10.54c-3.45 0-6.362.564-6.362 2.74S8.018 16 11.45 16c3.45 0 6.361-.564 6.361-2.74s-2.93-2.72-6.36-2.72Z"
      fill="#FF3939"
    />
    <path
      opacity={0.4}
      d="M11.449 8.467a4.207 4.207 0 0 0 4.21-4.233A4.206 4.206 0 0 0 11.448 0a4.206 4.206 0 0 0-4.21 4.234 4.207 4.207 0 0 0 4.21 4.233ZM20.588 5.22C21.192 2.842 19.42.707 17.164.707c-.245 0-.48.027-.71.073-.03.007-.064.022-.082.05-.02.034-.005.08.018.11a5.807 5.807 0 0 1 1.067 3.37 5.762 5.762 0 0 1-.984 3.241.158.158 0 0 0 .106.244c.158.028.32.043.484.047 1.643.043 3.118-1.02 3.525-2.622Z"
      fill="#FF3939"
    />
    <path
      d="M22.31 10.816c-.302-.644-1.028-1.087-2.132-1.304-.521-.127-1.931-.308-3.243-.283-.02.002-.03.016-.032.025-.003.013.002.034.028.048.607.301 2.95 1.614 2.655 4.38-.012.12.084.224.203.206.576-.083 2.06-.403 2.52-1.402a1.922 1.922 0 0 0 0-1.67Z"
      fill="#FF3939"
    />
    <path
      opacity={0.4}
      d="M6.545.78a3.514 3.514 0 0 0-.71-.073C3.58.707 1.808 2.842 2.414 5.22c.406 1.602 1.88 2.665 3.524 2.622.165-.004.327-.02.483-.047a.158.158 0 0 0 .107-.244 5.764 5.764 0 0 1-.984-3.24c0-1.252.39-2.416 1.068-3.372.022-.03.038-.075.016-.11C6.61.803 6.577.788 6.545.78Z"
      fill="#FF3939"
    />
    <path
      d="M2.822 9.513c-1.104.217-1.83.659-2.13 1.304a1.918 1.918 0 0 0 0 1.67c.46.998 1.943 1.32 2.52 1.402.119.018.214-.085.201-.206-.294-2.766 2.05-4.078 2.656-4.38.025-.015.03-.035.028-.049-.002-.009-.012-.022-.031-.024-1.313-.025-2.722.155-3.244.283Z"
      fill="#FF3939"
    />
  </svg>
)

export default PlayerIconActive