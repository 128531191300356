import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { dispatch } from '../store';

const initialState = {
  next: null,
  previous: null,
  current_page: 1,
  count: 1,
  total_pages: 1,
  results: [],
};

export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    setAgentList: (state, action) => {
      state.results = action.payload.results;
    },
  },
});

export const { setAgentList } = agentSlice.actions;
export default agentSlice.reducer;

export const getAgentData = async (query,order_by) => {
  try {
    const url = `/agent/list/?query=${query}&page_size=30000&order_by=${order_by}`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setAgentList(data));
    }
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};
