import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={21}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M14.575 0h3.386A2.549 2.549 0 0 1 20.5 2.56v3.415a2.549 2.549 0 0 1-2.539 2.56h-3.386a2.549 2.549 0 0 1-2.538-2.56V2.56A2.549 2.549 0 0 1 14.575 0Z"
      fill="#919EAB"
    />
    <path
      d="M6.424 11.466a2.549 2.549 0 0 1 2.539 2.56v3.414A2.55 2.55 0 0 1 6.424 20H3.04A2.55 2.55 0 0 1 .5 17.44v-3.415a2.549 2.549 0 0 1 2.539-2.56h3.385Zm11.538 0a2.549 2.549 0 0 1 2.538 2.56v3.414A2.55 2.55 0 0 1 17.962 20h-3.386a2.55 2.55 0 0 1-2.539-2.56v-3.415a2.549 2.549 0 0 1 2.538-2.56h3.387ZM6.424 0a2.549 2.549 0 0 1 2.539 2.56v3.415a2.549 2.549 0 0 1-2.539 2.56H3.04A2.549 2.549 0 0 1 .5 5.974V2.56A2.549 2.549 0 0 1 3.039 0h3.385Z"
      fill="#919EAB"
    />
  </svg>
)

export default SvgComponent
