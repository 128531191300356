import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import authReducer from './slices/auth';
import twoDReducer from './slices/twoD';
import threeDReducer from './slices/threeD';
import fourDReducer from './slices/fourD'
import agentReducer from './slices/agent';
import playerReducer from './slices/player';
import walletReducer from './slices/wallet';
import generalReducer from './slices/general';
import thaiReducer from './slices/thai';
import singaporeReducer from './slices/singapore';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  auth: authReducer,
  twoD: twoDReducer,
  threeD: threeDReducer,
  fourD: fourDReducer,
  agent: agentReducer,
  player: playerReducer,
  wallet: walletReducer,
  general: generalReducer,
  thai: thaiReducer,
  singapore: singaporeReducer,
});


export { rootPersistConfig, rootReducer };