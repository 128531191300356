import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from 'axios';

const initialState = {
  allowContactType: [],
  isGettingAllowContactType: false,

  promotionSliders: [],
  isGettingPromotionSliders: false,

  avatars: [],
  isGettingAvatars: false,
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setIsGettingAllowContactType: (state, action) => {
      state.isGettingAllowContactType = action.payload;
    },
    setAllowContactType: (state, action) => {
      state.allowContactType = action.payload;
    },

    setIsGettingPromotionSliders: (state, action) => {
      state.isGettingPromotionSliders = action.payload;
    },
    setPromotionSliders: (state, action) => {
      state.promotionSliders = action.payload;
    },

    setIsGettingAvatars: (state, action) => {
      state.isGettingAvatars = action.payload;
    },
    setAvatars: (state, action) => {
      state.avatars = action.payload;
    },
  },
});

export const {
  setAllowContactType,
  setIsGettingAllowContactType,
  setPromotionSliders,
  setIsGettingPromotionSliders,
  setAvatars,
  setIsGettingAvatars,
} = generalSlice.actions;

export default generalSlice.reducer;

export const handleGettingAllowContactType = async () => {
  dispatch(setIsGettingAllowContactType(true));
  try {
    let url = `agent/allow-contact-type/`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setAllowContactType(data));
      dispatch(setIsGettingAllowContactType(false));
    }
  } catch (err) {
    dispatch(setIsGettingAllowContactType(false));
  }
};

export const handleGettingPromotionSliders = async () => {
  dispatch(setIsGettingPromotionSliders(true));
  try {
    let url = `file/list/?page=1&page_size=100&type=Slider`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setPromotionSliders(data.results));
      dispatch(setIsGettingPromotionSliders(false));
    }
  } catch (err) {
    dispatch(setIsGettingPromotionSliders(false));
  }
};

export const handleGettingAvatars = async () => {
  dispatch(setIsGettingAvatars(true));
  try {
    let url = `file/list/?page=1&page_size=100&type=Avatar`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setAvatars(data.results));
      dispatch(setIsGettingAvatars(false));
    }
  } catch (err) {
    dispatch(setIsGettingAvatars(false));
  }
};
