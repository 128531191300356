import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import AuthLayout from '../layouts/AuthLayout';

// guards
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/common/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const ForgotPassword = Loadable(lazy(() => import('../pages/authentication/ForgotPassword')));
// const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
// const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
// const NewPassword = Loadable(lazy(() => import('../pages/authentication/NewPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));

// DASHBOARD

// GENERAL
const DashBoard = Loadable(lazy(() => import('../pages/general/dashboard/Dashboard')));
const Setting = Loadable(lazy(() => import('../pages/general/setting/Setting')));
const AdminProfile = Loadable(lazy(() => import('../pages/general/profile/AdminProfile')));

// 2D
const TwoDOddCard = Loadable(lazy(() => import('../pages/twoD/oddCard/OddCard')));
const TwoDTimePeriod = Loadable(lazy(() => import('../pages/twoD/timePeriod/TimePeriod')));
const TwoDEditTimePeriod = Loadable(lazy(() => import('../pages/twoD/timePeriod/EditTimePeriod')));
const TwoDSalesList = Loadable(lazy(() => import('../pages/twoD/salesList/SalesList')));
const TwoDWinnerList = Loadable(lazy(() => import('../pages/twoD/winnerList/WinnerList')));
const TwoDSetting = Loadable(lazy(() => import('../pages/twoD/setting/Setting')));

// 3D
const ThreeDOddCard = Loadable(lazy(() => import('../pages/threeD/oddCard/OddCard')));
const ThreeDTimePeriod = Loadable(lazy(() => import('../pages/threeD/timePeriod/TimePeriod')));
const ThreeDCreateNewPeriod = Loadable(lazy(() => import('../pages/threeD/timePeriod/CreateNewPeriod')));
const ThreeDEditTimePeriod = Loadable(lazy(() => import('../pages/threeD/timePeriod/EditTimePeriod')));
const ThreeDSalesList = Loadable(lazy(() => import('../pages/threeD/salesList/SalesList')));
const ThreeDWinnerList = Loadable(lazy(() => import('../pages/threeD/winnerList/WinnerList')));
const ThreeDSetting = Loadable(lazy(() => import('../pages/threeD/setting/Setting')));

// 4D
const FourDOddCard = Loadable(lazy(() => import('../pages/fourD/oddCard/oddCard')));
const FourDTimePeriod = Loadable(lazy(() => import('../pages/fourD/timePeriod/TimePeriod')));
const FourDEditTimePeriod = Loadable(lazy(() => import('../pages/fourD/timePeriod/EditTimePeriod')));
const FourDSalesList = Loadable(lazy(() => import('../pages/fourD/salesList/SalesList')));
const FourDWinnerList = Loadable(lazy(() => import('../pages/fourD/winnerList/WinnerList')));
const FourDSetting = Loadable(lazy(() => import('../pages/fourD/setting/Setting')));
// Thai
const ThaiTicketList = Loadable(lazy(() => import('../pages/thaiLottery/ticketList/TicketList')));
const ThaiTimePeriod = Loadable(lazy(() => import('../pages/thaiLottery/timePeriod/TimePeriod')));
const ThaiSalesList = Loadable(lazy(() => import('../pages/thaiLottery/salesList/SalesList')));
const ThaiWinnerList = Loadable(lazy(() => import('../pages/thaiLottery/winnerList/WinnerList')));
const ThaiSetting = Loadable(lazy(() => import('../pages/thaiLottery/setting/Setting')));
const ThaiPeriodDetail = Loadable(lazy(() => import('../pages/thaiLottery/timePeriod/TimePeriodDetail')));

// Thai
const SingaporeTicketList = Loadable(lazy(() => import('../pages/singaporeLottery/ticketList/TicketList')));
const SingaporeTimePeriod = Loadable(lazy(() => import('../pages/singaporeLottery/timePeriod/TimePeriod')));
const SingaporeSalesList = Loadable(lazy(() => import('../pages/singaporeLottery/salesList/SalesList')));
const SingaporeWinnerList = Loadable(lazy(() => import('../pages/singaporeLottery/winnerList/WinnerList')));
const SingaporeSetting = Loadable(lazy(() => import('../pages/singaporeLottery/setting/Setting')));
const SingaporePeriodDetail = Loadable(lazy(() => import('../pages/singaporeLottery/timePeriod/TimePeriodDetail')));

// Agent Management
const AgentList = Loadable(lazy(() => import('../pages/userManagement/agent/agentList/AgentList')));
const AgentView = Loadable(lazy(() => import('../pages/userManagement/agent/agentList/AgentView')));
const CommissionSetting = Loadable(
  lazy(() => import('../pages/userManagement/agent/commissionSetting/CommissionSetting'))
);

// Player
const Player = Loadable(lazy(() => import('../pages/userManagement/player/Player')));
const PlayerDetail = Loadable(lazy(() => import('../pages/userManagement/player/PlayerDetail')));

// Wallet
const FlowWallet = Loadable(lazy(() => import('../pages/wallet')));
const CheckoutList = Loadable(lazy(() => import('../pages/wallet/CheckoutList')));
const PlayerTransactionList = Loadable(lazy(() => import('../pages/wallet/PlayerTransactionList')));
const PaymentSetting = Loadable(lazy(() => import('../pages/wallet/PaymentSetting')));
const TransactionList = Loadable(lazy(() => import('../pages/wallet/TransactionList')));

const Page500 = Loadable(lazy(() => import('../pages/error/Page500')));
const PageMaintenance = Loadable(lazy(() => import('../pages/error/Maintenance')));
const Page404 = Loadable(lazy(() => import('../pages/error/Page404')));

export default function Router() {
  return useRoutes([
    // Authentication Routes
    {
      path: 'auth',
      element: (
        <GuestGuard>
          <AuthLayout />
        </GuestGuard>
      ),
      children: [
        { path: 'login', element: <Login /> },
        // { path: "register", element: <Register /> },
      ],
    },
    // Public Routes
    {
      path: 'auth',
      element: <AuthLayout />,
      children: [
        { path: 'forgot-password', element: <ForgotPassword /> },
        // { path: "verify", element: <VerifyCode /> },
        // { path: "reset-password", element: <ResetPassword /> },
      ],
    },
    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard" replace /> },
        { path: 'profile', element: <AdminProfile /> },
        { path: 'dashboard', element: <DashBoard /> },
        { path: 'setting', element: <Setting /> },

        // 2D Route
        {
          path: '2d',
          children: [
            { element: <Navigate to="/2d/odd-card" replace />, index: true },
            { path: 'odd-card', element: <TwoDOddCard /> },
            { path: 'time-period', element: <TwoDTimePeriod /> },
            { path: 'time-period/:date/:time/edit', element: <TwoDEditTimePeriod /> },
            { path: 'sales-list', element: <TwoDSalesList /> },
            { path: 'winner-list', element: <TwoDWinnerList /> },
            { path: 'setting', element: <TwoDSetting /> },
          ],
        },
        // 3D Route
        {
          path: '3d',
          children: [
            { element: <Navigate to="/3d/odd-card" replace />, index: true },
            { path: 'odd-card', element: <ThreeDOddCard /> },
            { path: 'time-period', element: <ThreeDTimePeriod /> },
            { path: 'time-period/create', element: <ThreeDCreateNewPeriod /> },
            { path: 'time-period/:date/edit', element: <ThreeDEditTimePeriod /> },
            { path: 'sales-list', element: <ThreeDSalesList /> },
            { path: 'winner-list', element: <ThreeDWinnerList /> },
            { path: 'setting', element: <ThreeDSetting /> },
          ],
        },
        // 4D Route
        {
          path: '4d',
          children: [
            { element: <Navigate to="/4d/odd-card" replace />, index: true },
            { path: 'odd-card', element: <FourDOddCard /> },
            { path: 'time-period', element: <FourDTimePeriod /> },
            { path: 'time-period/:date/edit', element: <FourDEditTimePeriod /> },
            { path: 'sales-list', element: <FourDSalesList /> },
            { path: 'winner-list', element: <FourDWinnerList /> },
            { path: 'setting', element: <FourDSetting /> },
          ],
        },
        // Thai Lottery Route
        {
          path: 'thai',
          children: [
            { element: <Navigate to="/thai/ticket-list" replace />, index: true },
            { path: 'ticket-list', element: <ThaiTicketList /> },
            { path: 'time-period', element: <ThaiTimePeriod /> },
            // { path: 'time-period/create', element: <ThreeDCreateNewPeriod /> },
            { path: 'time-period/:date/detail', element: <ThaiPeriodDetail /> },
            { path: 'sales-list', element: <ThaiSalesList /> },
            { path: 'winner-list', element: <ThaiWinnerList /> },
            { path: 'setting', element: <ThaiSetting /> },
          ],
        },
        // Singapore Lottery Route
        {
          path: 'singapore',
          children: [
            { element: <Navigate to="/singapore/ticket-list" replace />, index: true },
            { path: 'ticket-list', element: <SingaporeTicketList /> },
            { path: 'time-period', element: <SingaporeTimePeriod /> },
            // { path: 'time-period/create', element: <ThreeDCreateNewPeriod /> },
            { path: 'time-period/:date/detail', element: <SingaporePeriodDetail /> },
            { path: 'sales-list', element: <SingaporeSalesList /> },
            { path: 'winner-list', element: <SingaporeWinnerList /> },
            { path: 'setting', element: <SingaporeSetting /> },
          ],
        },

        // Account Management
        // Agent
        {
          path: 'agent',
          children: [
            { path: 'list', element: <AgentList /> },
            { path: ':id', element: <AgentView /> },
            { path: 'badge', element: <CommissionSetting /> },
          ],
          // element: <AgentList />
        },
        // User
        {
          path: 'player',
          children: [
            { element: <Navigate to="/player/list" replace />, index: true },
            { path: 'list', element: <Player /> },
            { path: ':id', element: <PlayerDetail /> },
          ],
        },

        // deposit
        {
          path: 'flow-wallet',
          children: [
            { element: <Navigate to="/flow-wallet/list" replace />, index: true },
            { path: 'list', element: <FlowWallet /> },
            { path: 'checkout-list', element: <CheckoutList /> },
            { path: 'player-transaction-list', element: <PlayerTransactionList /> },
            { path: 'payment-setting', element: <PaymentSetting /> },
            { path: 'transaction-list', element: <TransactionList /> },
          ],
        },
      ],
    },
    // Errors Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <PageMaintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}
