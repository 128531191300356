/* eslint-disable react/prop-types */
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Typography } from '@mui/material';
import useSettings from '../../hooks/useSettings';
import mainLogo from '../../assets/images/Royal-Lottery-Logo (1).png';

// ----------------------------------------------------------------------

export default function LetterLogo({ disabledLink = false, sx }) {
  const { themeMode } = useSettings();
  const logo = (
    <Box sx={{ width: 160, height: 40, ...sx }}>
      {themeMode === 'dark' ? (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '300px' }}>
          {' '}
          <img src={mainLogo} width="100px" alt="" />{' '}
          <Typography variant="h5" sx={{ width: '200px', marginLeft: '1rem', color:"white" }}>
            Royal Lottery
          </Typography>{' '}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '300px' }}>
          {' '}
          <img src={mainLogo} width="100px" alt="" />{' '}
          <Typography variant="h5" sx={{ width: '200px', marginLeft: '1rem', color:"black" }}>
            Royal Lottery
          </Typography>{' '}
        </Box>
      )}
    </Box>
  );

  if (disabledLink) {
    return (
      <>
        <img src={mainLogo} alt="" /> <h3>Royal Lottery</h3>
      </>
    );
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
