/* eslint-disable react/prop-types */
// hooks
import createAvatar from '../../utils/createAvatar';
import MAvatar from './MAvatar';
// ----------------------------------------------------------------------
export default function Avatar({ user, ...other }) {
  return (
    <MAvatar
      src={user?.staff_info?.avatar}
      alt={user?.first_name}
      color={
        user?.staff_info?.avatar && !user?.staff_info?.avatar.includes('default')
          ? 'default'
          : createAvatar(user?.first_name).color
      }
      {...other}
    >
      {user?.staff_info?.avatar && user?.staff_info?.avatar.includes('default') && createAvatar(user?.first_name).name}
    </MAvatar>
  );
}

//
