import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { isValidToken, setAxiosToken } from '../../utils/jwt';
import { dispatch } from '../store';
import { handleGettingAdminPaymentMethods } from './wallet';
const initialState = {
  isLoggedIn: false,
  accessToken: null,
  refreshToken: null,
  user: null,
  isReady: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signinSuccess: (state, action) => {
      state.isLoggedIn = true;
      state.isReady = false;
      state.accessToken = action.payload.access;
      state.refreshToken = action.payload.refresh;
    },
    getUserSuccess: (state, action) => {
      state.user = action.payload;
      state.isReady = true;
    },
    signOutSuccess: (state) => {
      state.isLoggedIn = false;
      state.accessToken = null;
      state.refreshToken = null;
    },
    setIsReady: (state) => {
      state.isReady = true;
    },
  },
});

export const { signinSuccess, getUserSuccess, signOutSuccess, setIsReady } = authSlice.actions;

export default authSlice.reducer;

// actions
export const handleSignOut = () => {
  localStorage.removeItem('token');
  setAxiosToken();
  dispatch(signOutSuccess());
};

export const setOldToken = () => {
  const TokenString = localStorage.getItem('token');
  if (TokenString) {
    const token = JSON.parse(TokenString);
    if (isValidToken(token.access)) {
      setAxiosToken(token.access);
      dispatch(signinSuccess(token));
      getUser();
    } else {
      // something to do
    }
  } else {
    dispatch(setIsReady());
  }
};

export const handleSignInSuccess = (token) => {
  if (token.type === 'Staff') {
    if (token.access) {
      localStorage.setItem('token', JSON.stringify(token));
    }
    setAxiosToken(token.access);
    dispatch(signinSuccess(token));
    getUser();
  }
  // Alert
};

// User
export const getUser = () => {
  const url = `/user/staff/`;
  axios.get(url).then(async ({ data }) => {
    dispatch(getUserSuccess(data));
    await handleGettingAdminPaymentMethods();
  });
};
