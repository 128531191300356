import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#929EAB">
      <path
        opacity={0.4}
        d="M18.11 0H6.899C2.816 0 .5 2.316.5 6.398v11.199C.5 21.684 2.816 24 6.898 24h11.213c4.087 0 6.389-2.316 6.389-6.398V6.398C24.5 2.316 22.198 0 18.11 0Z"
      />
      <path d="M6.631 10.158h-1.81c.057-.82.357-1.477.9-1.973.545-.497 1.2-.746 1.965-.746.839 0 1.551.24 2.137.713.586.473.882 1.045.882 1.715 0 .732-.361 1.388-1.083 1.978.46.291.792.596 1.008.91.21.319.318.694.318 1.134 0 .84-.276 1.51-.834 2.016-.558.506-1.294.76-2.213.76-.853 0-1.556-.244-2.118-.727-.563-.483-.9-1.158-1.032-2.016h1.852c.22.853.642 1.28 1.27 1.28.314 0 .577-.113.793-.333.215-.225.323-.497.323-.825 0-.403-.131-.731-.39-.99-.257-.257-.594-.383-1.002-.383h-.328v-1.51h.197c.426 0 .768-.103 1.026-.314.258-.21.384-.487.384-.834 0-.314-.103-.582-.304-.802a.98.98 0 0 0-.746-.333c-.689-.004-1.087.422-1.195 1.28ZM12.631 7.331h3.145c1.346 0 2.424.422 3.244 1.27.82.849 1.228 1.965 1.228 3.352 0 1.392-.422 2.503-1.265 3.342-.844.84-1.97 1.257-3.375 1.257H12.63v-9.22Zm2.002 1.744v5.695h.787c.816 0 1.486-.22 2.011-.665.525-.446.788-1.186.788-2.227 0-.604-.127-1.139-.38-1.603-.253-.464-.619-.783-1.102-.947a4.242 4.242 0 0 0-1.373-.248l-.731-.005Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgComponent
