import * as React from "react"

const SingaporeIconActive = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#FF3939">
      <path
        opacity={0.4}
        d="M17.61 0H6.399C2.316 0 0 2.316 0 6.398v11.199C0 21.684 2.316 24 6.398 24h11.213C21.698 24 24 21.684 24 17.602V6.398C24 2.316 21.698 0 17.61 0Z"
      />
      <path d="M9.862 8.25 8.625 9.605c-.652-.385-1.12-.577-1.406-.577a.764.764 0 0 0-.567.249.825.825 0 0 0-.24.59c0 .455.413.849 1.238 1.181.605.258 1.06.493 1.369.699.31.206.567.497.778.867.21.37.314.769.314 1.2 0 .792-.319 1.481-.956 2.063a3.232 3.232 0 0 1-2.264.876c-.957 0-1.899-.45-2.832-1.35l1.308-1.523c.586.604 1.135.904 1.645.904.24 0 .47-.107.69-.318.22-.211.328-.436.328-.67 0-.483-.507-.905-1.524-1.271-.581-.211-.994-.412-1.237-.595-.244-.183-.45-.455-.614-.807a2.537 2.537 0 0 1-.249-1.082c0-.82.267-1.486.802-1.997.534-.511 1.233-.764 2.09-.764 1.013.009 1.87.332 2.564.97ZM19.369 9.417l-1.322 1.238c-.277-.478-.619-.835-1.027-1.074a2.538 2.538 0 0 0-1.28-.356c-.721 0-1.345.272-1.88.82-.529.549-.796 1.196-.796 1.94 0 .793.267 1.463.797 2.012.53.548 1.172.825 1.922.825.408 0 .797-.103 1.176-.31.375-.206.68-.59.91-1.157h-2.185v-1.796h4.257v1.196c0 .614-.211 1.27-.633 1.978-.422.703-.975 1.218-1.664 1.547a4.799 4.799 0 0 1-2.086.487c-1.275 0-2.349-.46-3.225-1.383-.877-.923-1.313-2.062-1.313-3.421 0-1.29.45-2.386 1.35-3.3.9-.915 1.988-1.37 3.253-1.37 1.632-.004 2.874.704 3.746 2.124Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SingaporeIconActive
