import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.85 14.858c-3.964 0-7.35.612-7.35 3.06S5.864 21 9.85 21c3.963 0 7.349-.612 7.349-3.06 0-2.449-3.364-3.082-7.35-3.082Z"
      fill="#919EAB"
    />
    <path
      opacity={0.4}
      d="M9.85 12.525c2.699 0 4.862-2.119 4.862-4.763C14.712 5.12 12.55 3 9.85 3 7.151 3 4.986 5.119 4.986 7.762c0 2.644 2.165 4.763 4.863 4.763ZM16.674 7.849a6.368 6.368 0 0 1-1.137 3.646c-.076.107-.01.252.122.275.182.03.369.048.56.052 1.898.048 3.601-1.148 4.072-2.95.698-2.675-1.35-5.077-3.957-5.077a4.16 4.16 0 0 0-.818.082c-.036.008-.075.025-.095.055-.025.04-.007.09.019.124a6.414 6.414 0 0 1 1.234 3.793Z"
      fill="#919EAB"
    />
    <path
      d="M22.28 15.17c-.348-.726-1.187-1.223-2.463-1.468-.601-.143-2.232-.348-3.747-.319-.023.003-.035.018-.037.028-.003.015.003.038.033.055.7.339 3.408 1.814 3.067 4.927a.207.207 0 0 0 .234.232c.667-.093 2.38-.455 2.912-1.578a2.107 2.107 0 0 0 0-1.878Z"
      fill="#919EAB"
    />
  </svg>
)

export default SvgComponent
