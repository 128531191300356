import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from 'axios';
import { groupBy } from '../../utils/stringFormat';

const initialState = {
  twoDList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingTwoDList: false,

  thaiList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingThaiList: false,

  singaporeList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingsingaporeList: false,

  threeDList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingThreeDList: false,

  depositList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingDepositList: false,

  withdrawList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingWithdrawList: false,
  playersList: {},
};

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    clearsingaporeList: (state) => {
      state.singaporeList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingsingaporeList: (state, action) => {
      state.isGettingTimePeriods = action.payload;
    },
    setsingaporeList: (state, action) => {
      var data = action.payload;

      state.singaporeList = {
        ...data,
        results: [...state.singaporeList.results, ...data.results],
      };
    },

    clearThaiList: (state) => {
      state.thaiList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingThaiList: (state, action) => {
      state.isGettingTimePeriods = action.payload;
    },
    setThaiList: (state, action) => {
      var data = action.payload;

      state.thaiList = {
        ...data,
        results: [...state.thaiList.results, ...data.results],
      };
    },

    clearTwoDList: (state) => {
      state.twoDList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingTwoDList: (state, action) => {
      state.isGettingTimePeriods = action.payload;
    },
    setTwoDList: (state, action) => {
      var data = action.payload;

      state.twoDList = {
        ...data,
        results: [...state.twoDList.results, ...data.results],
      };
    },

    clearThreeDList: (state) => {
      state.threeDList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingThreeDList: (state, action) => {
      state.isGettingTimePeriods = action.payload;
    },
    setThreeDList: (state, action) => {
      var data = action.payload;
      const results = [...state.threeDList.results, ...data.results];
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: results,
      };
      state.threeDList = newState;
    },

    clearDepositList: (state) => {
      state.depositList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingDepositList: (state, action) => {
      state.isGettingDepositList = action.payload;
    },
    setDepositList: (state, action) => {
      var data = action.payload;
      const results = [...state.depositList.results, ...data.results];
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: results,
      };
      state.depositList = newState;
    },

    clearWithdrawList: (state) => {
      state.withdrawList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingWithdrawList: (state, action) => {
      state.isGettingWithdrawList = action.payload;
    },
    setWithdrawList: (state, action) => {
      var data = action.payload;
      const results = [...state.withdrawList.results, ...data.results];
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: results,
      };
      state.withdrawList = newState;
    },

    setPlayersList: (state, action) => {
      state.playersList = action.payload;
    },
  },
});

export const {
  setIsGettingsingaporeList,
  setsingaporeList,
  clearsingaporeList,

  setIsGettingThaiList,
  setThaiList,
  clearThaiList,

  setIsGettingTwoDList,
  setTwoDList,
  clearTwoDList,
  setIsGettingThreeDList,
  setThreeDList,
  clearThreeDList,
  setIsGettingDepositList,
  setDepositList,
  clearDepositList,
  setIsGettingWithdrawList,
  setWithdrawList,
  clearWithdrawList,
  setPlayersList,
} = playerSlice.actions;

export default playerSlice.reducer;

export const handleGettingTwoDList = async (next, pid) => {
  if (next) {
    dispatch(setIsGettingTwoDList(true));
    if (parseInt(next) == 1) {
      dispatch(clearTwoDList());
    }
    try {
      const url = `2d/bet-order-list/?page=${next}&page_size=300&pid=${pid}`;
      const res = await axios.get(url);
      if (res.status === 200) {
        const data = res.data;
        dispatch(setTwoDList(data));
        dispatch(setIsGettingTwoDList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingTwoDList(false));
      return Promise.reject(err);
    }
  }
};

export const handleGroupTwoDArray = (arr) => {
  const grouped = groupBy(arr, 'date').reverse();
  var dataResults = [];
  grouped.forEach((item) => {
    dataResults.push(groupBy(item, 'time'));
  });
  return dataResults;
};

export const handleGettingThreeDList = async (next, pid) => {
  if (next) {
    dispatch(setIsGettingThreeDList(true));
    if (parseInt(next) == 1) {
      dispatch(clearThreeDList());
    }
    try {
      const url = `3d/bet-order-list/?page=${next}&page_size=500&pid=${pid}`;
      const res = await axios.get(url);
      if (res.status === 200) {
        const data = res.data;
        dispatch(setThreeDList(data));
        dispatch(setIsGettingThreeDList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingThreeDList(false));
      return Promise.reject(err);
    }
  }
};

export const handleGroupThreeDArray = (arr) => {
  const grouped = groupBy(arr, 'date').reverse();

  var dataResults = [];
  grouped.forEach((item) => {
    dataResults.push(groupBy(item, 'created_at'));
  });
  return dataResults;
};

export const handleGettingDepositList = async (next, pid) => {
  if (next) {
    dispatch(setIsGettingDepositList(true));
    if (parseInt(next) == 1) {
      dispatch(clearDepositList());
    }
    try {
      const url = `wallet/player/transition-list/?page=${next}&page_size=500&pid=${pid}&type=deposit`;
      const res = await axios.get(url);
      if (res.status === 200) {
        const data = res.data;
        dispatch(setDepositList(data));
        dispatch(setIsGettingDepositList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingDepositList(false));
      return Promise.reject(err);
    }
  }
};

export const handleGroupDepositArray = (arr) => {
  const grouped = groupBy(arr, 'created_at').reverse();
  return grouped;
};

export const handleGettingWithdrawList = async (next, pid) => {
  if (next) {
    dispatch(setIsGettingWithdrawList(true));
    if (parseInt(next) == 1) {
      dispatch(clearWithdrawList());
    }
    try {
      const url = `wallet/player/transition-list/?page=${next}&page_size=500&pid=${pid}&type=withdraw`;
      const res = await axios.get(url);
      if (res.status === 200) {
        const data = res.data;
        dispatch(setWithdrawList(data));
        dispatch(setIsGettingWithdrawList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingWithdrawList(false));
      return Promise.reject(err);
    }
  }
};

export const handleGroupWithdrawArray = (arr) => {
  const grouped = groupBy(arr, 'created_at').reverse();
  return grouped;
};

export const handleGettingPlayersList = async () => {
  try {
    const url = `player/list/?page=1&page_size=10000000&query=&code=&status=/`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setPlayersList(data));
    }
    return Promise.resolve();
  } catch (err) {
    dispatch(setIsGettingThreeDList(false));
    return Promise.reject(err);
  }
};

export const handleGettingThaiList = async (next, pid) => {
  if (next) {
    dispatch(setIsGettingThaiList(true));

    try {
      const url = `thai/bet-order-list/?page=${next}&page_size=500&pid=${pid}`;
      const res = await axios.get(url);
      if (parseInt(next) == 1) {
        dispatch(clearThaiList());
      }

      if (res.status === 200) {
        const data = res.data;
        dispatch(setThaiList(data));
        dispatch(setIsGettingThaiList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingThaiList(false));
      return Promise.reject(err);
    }
  }
};

export const handleGroupThaiArray = (arr) => {
  const grouped = groupBy(arr, 'date').reverse();
  var dataResults = [];
  grouped.forEach((item) => {
    dataResults.push(groupBy(item, 'created_at'));
  });
  return dataResults;
};

export const handleGettingsingaporeList = async (next, pid) => {
  if (next) {
    dispatch(setIsGettingsingaporeList(true));
    if (parseInt(next) == 1) {
      dispatch(clearsingaporeList());
    }
    try {
      const url = `singapore/bet-order-list/?page=${next}&page_size=500&pid=${pid}`;
      const res = await axios.get(url);
      if (res.status === 200) {
        const data = res.data;
        dispatch(setsingaporeList(data));
        dispatch(setIsGettingsingaporeList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingsingaporeList(false));
      return Promise.reject(err);
    }
  }
};

export const handleGroupsingaporeArray = (arr) => {
  const grouped = groupBy(arr, 'date').reverse();
  var dataResults = [];
  grouped.forEach((item) => {
    dataResults.push(groupBy(item, 'created_at'));
  });
  return dataResults;
};
