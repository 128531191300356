import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.5}
      width={24}
      height={24}
      rx={6}
      fill="#929EAB"
      fillOpacity={0.4}
    />
    <path
      d="M8.47 7.324h1.722v5.286h1.338v1.47h-1.338V16H8.506l.006-1.92H4.516v-1.44L8.47 7.324ZM6.136 12.61h2.376V9.43l-2.376 3.18Zm6.711-5.286h1.08c.276 0 .55.002.822.006.276 0 .506.002.69.006 1.052.024 1.91.212 2.574.564.664.352 1.15.844 1.458 1.476.312.632.468 1.38.468 2.244 0 .9-.164 1.678-.492 2.334a3.418 3.418 0 0 1-1.506 1.512c-.672.356-1.526.534-2.562.534h-2.532V7.324Zm1.764 1.416v5.844h.804c.496 0 .946-.096 1.35-.288.408-.192.734-.506.978-.942.244-.436.366-1.018.366-1.746 0-.676-.108-1.224-.324-1.644a2.021 2.021 0 0 0-.924-.93c-.4-.196-.874-.294-1.422-.294h-.828Z"
      fill="#929EAB"
    />
  </svg>
)

export default SvgComponent
