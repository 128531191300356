import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axios from 'axios';
import { groupBy } from '../../utils/stringFormat';

const initialState = {
  adminPaymentMethods: [],
  isGettingAdminPaymentMethods: false,

  allowPaymentMethods: [],
  isGettingAllowPaymentMethods: false,

  adminPaymentMethods: [],
  isGettingAdminPaymentMethods: false,

  requestPendingList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingRequestPendingList: false,
  requestHistoryList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingRequestHistoryList: false,

  checkoutPendingList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingCheckoutPendingList: false,

  agentCheckoutPendingList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingAgentPendingList: false,

  agentCheckoutPendingListBadge: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingAgentPendingListBadge: false,

  checkoutHistoryList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingCheckoutHistoryList: false,

  commissionList: {
    next: 1,
    previous: null,
    current_page: 1,
    count: 1,
    total_pages: 1,
    results: [],
  },
  isGettingCommissionList: false,
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    clearRequestPendingList: (state) => {
      state.requestPendingList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingRequestPendingList: (state, action) => {
      state.isGettingRequestPendingList = action.payload;
    },
    setRequestPendingList: (state, action) => {
      var data = action.payload;
      const results = [...state.requestPendingList.results, ...data.results];
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: results,
      };
      state.requestPendingList = newState;
    },

    clearRequestHistoryList: (state) => {
      state.requestHistoryList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingRequestHistoryList: (state, action) => {
      state.isGettingRequestHistoryList = action.payload;
    },
    setRequestHistoryList: (state, action) => {
      var data = action.payload;
      const results = [...state.requestHistoryList.results, ...data.results];
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: results,
      };
      state.requestHistoryList = newState;
    },

    clearCheckoutPendingList: (state) => {
      state.checkoutPendingList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingCheckoutPendingList: (state, action) => {
      state.isGettingCheckoutPendingList = action.payload;
    },
    setCheckoutPendingList: (state, action) => {
      var data = action.payload;
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: data.results,
      };
      state.checkoutPendingList = newState;
    },
    setIsGettingAgentPendingList: (state, action) => {
      state.isGettingAgentPendingList = action.payload;
    },

    setAgentCheckoutPendingList: (state, action) => {
      var data = action.payload;
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: data.results,
      };
      state.agentCheckoutPendingList = newState;
    },

    setIsGettingAgentPendingListBadge: (state, action) => {
      state.isGettingAgentPendingListBadge = action.payload;
    },

    setAgentCheckoutPendingListBadge: (state, action) => {
      var data = action.payload;
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: data.results,
      };
      state.agentCheckoutPendingListBadge = newState;
    },

    clearCheckoutHistoryList: (state) => {
      state.checkoutHistoryList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingCheckoutHistoryList: (state, action) => {
      state.isGettingCheckoutHistoryList = action.payload;
    },
    setCheckoutHistoryList: (state, action) => {
      var data = action.payload;
      const results = [...state.checkoutHistoryList.results, ...data.results];
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: results,
      };
      state.checkoutHistoryList = newState;
    },

    clearCommissionList: (state) => {
      state.commissionList = {
        next: 1,
        previous: null,
        current_page: 1,
        count: 1,
        total_pages: 1,
        dates: [],
        results: [],
      };
    },
    setIsGettingCommissionList: (state, action) => {
      state.isGettingCommissionList = action.payload;
    },
    setCommissionList: (state, action) => {
      var data = action.payload;
      const results = [...state.commissionList.results, ...data.results];
      var newState = {
        next: data.next,
        previous: data.previous,
        current_page: data.current_page,
        count: data.count,
        total_pages: data.total_pages,
        results: results,
      };
      state.commissionList = newState;
    },

    setIsGettingAdminPaymentMethods: (state, action) => {
      state.isGettingAdminPaymentMethods = action.payload;
    },
    setAdminPaymentMethods: (state, action) => {
      state.adminPaymentMethods = action.payload;
    },

    setIsGettingAllowPaymentMethods: (state, action) => {
      state.isGettingAllowPaymentMethods = action.payload;
    },
    setAllowPaymentMethods: (state, action) => {
      state.allowPaymentMethods = action.payload;
    },
  },
});

export const {
  setAdminPaymentMethods,
  setIsGettingAdminPaymentMethods,

  setAllowPaymentMethods,
  setIsGettingAllowPaymentMethods,

  clearRequestPendingList,
  setIsGettingRequestPendingList,
  setRequestPendingList,
  clearRequestHistoryList,
  setIsGettingRequestHistoryList,
  setRequestHistoryList,

  clearCheckoutPendingList,
  setIsGettingCheckoutPendingList,
  setCheckoutPendingList,
  setIsGettingAgentPendingList,
  setAgentCheckoutPendingList,
  setIsGettingAgentPendingListBadge,
  setAgentCheckoutPendingListBadge,
  clearCheckoutHistoryList,
  setIsGettingCheckoutHistoryList,
  setCheckoutHistoryList,

  clearCommissionList,
  setIsGettingCommissionList,
  setCommissionList,

  setAgentPaymentMethods,
  setIsGettingAgentPaymentMethods,
} = walletSlice.actions;

export default walletSlice.reducer;

export const handleGettingRequestPendingList = async (next) => {
  if (next) {
    dispatch(setIsGettingRequestPendingList(true));
    const state = store.getState();
    var aid = state.auth.user.agent_info.id;
    try {
      const url = `wallet/player/transition-list/?page=${next}&page_size=1000&aid=${aid}&status=Pending`;
      const res = await axios.get(url);
      if (res.status === 200) {
        const data = res.data;
        if (next == 1) {
          dispatch(clearRequestPendingList());
        }
        dispatch(setRequestPendingList(data));
        dispatch(setIsGettingRequestPendingList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingRequestPendingList(false));
      return Promise.reject(err);
    }
  }
};

export const handleGettingRequestHistoryList = async (next) => {
  if (next) {
    dispatch(setIsGettingRequestHistoryList(true));
    const state = store.getState();
    var aid = state.auth.user.agent_info.id;
    try {
      const url = `wallet/player/transition-list/?page=${next}&page_size=30&aid=${aid}&status=-Pending`;
      const res = await axios.get(url);

      if (res.status === 200) {
        const data = res.data;
        if (parseInt(next) == 1) {
          dispatch(clearRequestHistoryList());
        }
        dispatch(setRequestHistoryList(data));
        dispatch(setIsGettingRequestHistoryList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingRequestHistoryList(false));
      return Promise.reject(err);
    }
  }
};

export const PlayerRequestPendingList = async (query = '', order_by = '-updated_at', status = 'pending', type = '') => {
  try {
    const url = `wallet/player/transition-list/?query=${query}&order_by=${order_by}&status=${status}&type=${type}&to_admin`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setCheckoutPendingList(data));
      dispatch(setIsGettingCheckoutPendingList(false));
    }
    return Promise.resolve();
  } catch (err) {
    dispatch(setIsGettingCheckoutPendingList(false));
    return Promise.reject(err);
  }
};

export const AgentRequestPendingList = async (
  page = 0,
  query = '',
  pageSize = 10000,
  order_by = '-updated_at',
  type = '',
  status = ''
) => {
  try {
    const url = `wallet/agent/transition-list/?page=${
      page + 1
    }&query=${query}&page_size=${pageSize}&order_by=${order_by}&type=${type === 'all' ? '' : type}&status=${
      status === 'history' ? '-Pending' : status
    }`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setAgentCheckoutPendingList(data));
      dispatch(setIsGettingAgentPendingList(false));
    }
    return Promise.resolve();
  } catch (err) {
    dispatch(setIsGettingAgentPendingList(false));
    return Promise.reject(err);
  }
};

export const AgentRequestPendingListBadge = async (
  page = 0,
  query = '',
  pageSize = 10000,
  order_by = '-updated_at'
) => {
  try {
    const url = `wallet/agent/transition-list/?page=${
      page + 1
    }&query=${query}&page_size=${pageSize}&order_by=${order_by}&type=&status=Pending`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setAgentCheckoutPendingListBadge(data));
      dispatch(setIsGettingAgentPendingListBadge(false));
    }
    return Promise.resolve();
  } catch (err) {
    dispatch(setIsGettingAgentPendingListBadge(false));
    return Promise.reject(err);
  }
};

export const handleGettingCheckoutHistoryList = async (next, filter) => {
  if (next) {
    const { type, startDate, toDate } = filter;
    dispatch(setIsGettingCheckoutHistoryList(true));

    try {
      let url = '';
      if (startDate && toDate) {
        url = `wallet/agent/my-transition-list/?page=${next}&page_size=30&status=-Pending&type=${type}&start_date=${filter.startDate}&to_date=${filter.toDate}`;
      } else {
        url = `wallet/agent/my-transition-list/?page=${next}&page_size=30&status=-Pending&type=${type}`;
      }
      const res = await axios.get(url);
      if (parseInt(next) == 1) {
        dispatch(clearCheckoutHistoryList());
      }

      if (res.status === 200) {
        const data = res.data;
        dispatch(setCheckoutHistoryList(data));
        dispatch(setIsGettingCheckoutHistoryList(false));
      }
      return Promise.resolve();
    } catch (err) {
      dispatch(setIsGettingCheckoutHistoryList(false));
      return Promise.reject(err);
    }
  }
};

export const handleGettingAdminPaymentMethods = async () => {
  dispatch(setIsGettingAdminPaymentMethods(true));
  try {
    let url = `wallet/admin-payment-methods/`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setAdminPaymentMethods(data));
      dispatch(setIsGettingAdminPaymentMethods(false));
    }
  } catch (err) {
    dispatch(setIsGettingAdminPaymentMethods(false));
  }
};

export const handleGettingAllowPaymentMethods = async () => {
  dispatch(setIsGettingAllowPaymentMethods(true));
  try {
    let url = `wallet/allow-payment-methods/`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setAllowPaymentMethods(data));
      dispatch(setIsGettingAllowPaymentMethods(false));
    }
  } catch (err) {
    dispatch(setIsGettingAllowPaymentMethods(false));
  }
};
