/* eslint-disable no-unused-vars */
import axios from 'axios';
import { TextField, InputAdornment, Link, IconButton, Stack, Snackbar, Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_AUTH } from '../../../routes/paths';
import { handleSignInSuccess } from '../../../redux/slices/auth';

function LoginForm(_props) {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openLoginAlert, setOpenLoginAlert] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await axios.post('auth/login/', {
        password,
        phone_number: phoneNumber,
      });
      if (res.status === 200) {
        if (res.data.type === 'Staff') {
          handleSignInSuccess(res.data);
          setLoading(false);
        } else {
          setOpenLoginAlert(true);
          setLoading(false);
        }
      }
      return Promise.resolve();
    } catch (err) {
      setLoading(false);
      return Promise.reject(err);
    }
  };
  useEffect(
    () => () => {
      setPhoneNumber('');
      setPassword('');
      setLoading(false);
    },
    []
  );
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            required
            type={'number'}
            label={'Phone Number'}
            autoComplete="phone"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
          <TextField
            fullWidth
            required
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.forgotPassword}>
              Forgot password?
            </Link>
          </Stack>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
            Login
          </LoadingButton>
        </Stack>
      </form>
      <Snackbar
        open={openLoginAlert}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => {
          setOpenLoginAlert(false);
        }}
      >
        <Alert
          onClose={() => {
            setOpenLoginAlert(false);
          }}
          severity="error"
          sx={{ width: '100%' }}
        >
          Only Admin can LoggedIn into Admin DashBoard
        </Alert>
      </Snackbar>
    </>
  );
}

export default LoginForm;
