// ----------------------------------------------------------------------

function path(root, subLink) {
  return `${root}${subLink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    profile: path(ROOTS_DASHBOARD, 'profile'),
    dashboard: path(ROOTS_DASHBOARD, 'dashboard'),
    setting: path(ROOTS_DASHBOARD, 'setting'),
  },
  TwoD: {
    root: path(ROOTS_DASHBOARD, '/2d'),
    oddCard: path(ROOTS_DASHBOARD, '2d/odd-card'),
    timePeriod: path(ROOTS_DASHBOARD, '2d/time-period'),
    createTimePeriod: path(ROOTS_DASHBOARD, '2d/time-period/create'),
    edit: (date, time) => path(ROOTS_DASHBOARD, `2d/time-period/${date}/${time}/edit`),
    salesList: path(ROOTS_DASHBOARD, '2d/sales-list'),
    winnerList: path(ROOTS_DASHBOARD, '2d/winner-list'),
    setting: path(ROOTS_DASHBOARD, '2d/setting'),
  },
  ThreeD: {
    root: path(ROOTS_DASHBOARD, '/3d'),
    oddCard: path(ROOTS_DASHBOARD, '3d/odd-card'),
    timePeriod: path(ROOTS_DASHBOARD, '3d/time-period'),
    createTimePeriod: path(ROOTS_DASHBOARD, '3d/time-period/create'),
    edit: (date) => path(ROOTS_DASHBOARD, `3d/time-period/${date}/edit`),
    salesList: path(ROOTS_DASHBOARD, '3d/sales-list'),
    winnerList: path(ROOTS_DASHBOARD, '3d/winner-list'),
    setting: path(ROOTS_DASHBOARD, '3d/setting'),
  },
  FourD: {
    root: path(ROOTS_DASHBOARD, '/4d'),
    oddCard: path(ROOTS_DASHBOARD, '4d/odd-card'),
    timePeriod: path(ROOTS_DASHBOARD, '4d/time-period'),
    edit: (date) => path(ROOTS_DASHBOARD, `4d/time-period/${date}/edit`),
    salesList: path(ROOTS_DASHBOARD, '4d/sales-list'),
    winnerList: path(ROOTS_DASHBOARD, '4d/winner-list'),
    setting: path(ROOTS_DASHBOARD, '4d/setting'),
  },
  Thai: {
    root: path(ROOTS_DASHBOARD, '/thai'),
    ticketList: path(ROOTS_DASHBOARD, 'thai/ticket-list'),
    timePeriod: path(ROOTS_DASHBOARD, 'thai/time-period'),
    // createTimePeriod: path(ROOTS_DASHBOARD, 'thai/time-period/create'),
    detail: (date) => path(ROOTS_DASHBOARD, `thai/time-period/${date}/detail`),
    salesList: path(ROOTS_DASHBOARD, 'thai/sales-list'),
    winnerList: path(ROOTS_DASHBOARD, 'thai/winner-list'),
    setting: path(ROOTS_DASHBOARD, 'thai/setting'),
  },
  Singapore: {
    root: path(ROOTS_DASHBOARD, '/singapore'),
    ticketList: path(ROOTS_DASHBOARD, 'singapore/ticket-list'),
    timePeriod: path(ROOTS_DASHBOARD, 'singapore/time-period'),
    // createTimePeriod: path(ROOTS_DASHBOARD, 'singapore/time-period/create'),
    detail: (date) => path(ROOTS_DASHBOARD, `singapore/time-period/${date}/detail`),
    salesList: path(ROOTS_DASHBOARD, 'singapore/sales-list'),
    winnerList: path(ROOTS_DASHBOARD, 'singapore/winner-list'),
    setting: path(ROOTS_DASHBOARD, 'singapore/setting'),
  },
  // AccountManagement
  Agent: {
    root: path(ROOTS_DASHBOARD, '/agent'),
    agentList: path(ROOTS_DASHBOARD, 'agent/list'),
    agentCreate: path(ROOTS_DASHBOARD, 'agent/create'),
    view: (id) => path(ROOTS_DASHBOARD, `agent/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `agent/${id}/edit`),
    badge: path(ROOTS_DASHBOARD, 'agent/badge'),
  },
  Player: {
    player: path(ROOTS_DASHBOARD, 'player'),
    detail: (id) => path(ROOTS_DASHBOARD, `player/${id}`),
  },
  Wallet: {
    index: path(ROOTS_DASHBOARD, 'flow-wallet'),
    list: path(ROOTS_DASHBOARD, 'flow-wallet/list'),
    checkout: path(ROOTS_DASHBOARD, 'flow-wallet/checkout-list'),
    player: path(ROOTS_DASHBOARD, 'flow-wallet/player-transaction-list'),
    payment: path(ROOTS_DASHBOARD, 'flow-wallet/payment-setting'),
    transaction: path(ROOTS_DASHBOARD, 'flow-wallet/transaction-list'),
  },
};
