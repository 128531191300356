import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.45 14.54c-3.45 0-6.362.564-6.362 2.74S9.018 20 12.45 20c3.45 0 6.361-.564 6.361-2.74s-2.93-2.72-6.36-2.72Z"
      fill="#919EAB"
    />
    <path
      opacity={0.4}
      d="M12.449 12.467a4.207 4.207 0 0 0 4.21-4.233A4.206 4.206 0 0 0 12.448 4a4.206 4.206 0 0 0-4.21 4.234 4.207 4.207 0 0 0 4.21 4.233ZM21.588 9.22c.604-2.378-1.168-4.513-3.424-4.513-.245 0-.48.027-.71.072-.03.008-.064.023-.082.05-.02.034-.005.08.018.11a5.807 5.807 0 0 1 1.067 3.37 5.762 5.762 0 0 1-.984 3.242.158.158 0 0 0 .106.244c.158.028.32.042.484.047 1.643.043 3.118-1.02 3.525-2.623Z"
      fill="#919EAB"
    />
    <path
      d="M23.31 14.817c-.302-.645-1.028-1.087-2.132-1.304-.521-.128-1.931-.308-3.243-.284-.02.003-.03.017-.032.026-.003.012.002.034.028.047.607.302 2.95 1.614 2.655 4.381-.012.12.084.224.203.206.576-.083 2.06-.404 2.52-1.402a1.922 1.922 0 0 0 0-1.67Z"
      fill="#919EAB"
    />
    <path
      opacity={0.4}
      d="M7.545 4.78a3.514 3.514 0 0 0-.71-.073c-2.256 0-4.028 2.135-3.422 4.512.406 1.603 1.88 2.666 3.524 2.623.165-.005.327-.02.483-.047a.158.158 0 0 0 .107-.244 5.764 5.764 0 0 1-.984-3.241c0-1.251.39-2.415 1.068-3.37.022-.03.038-.077.016-.11-.018-.029-.05-.043-.082-.05Z"
      fill="#919EAB"
    />
    <path
      d="M3.822 13.513c-1.104.217-1.83.659-2.13 1.304a1.918 1.918 0 0 0 0 1.67c.46.998 1.943 1.32 2.52 1.402.119.018.214-.085.201-.206-.294-2.766 2.05-4.078 2.656-4.38.025-.014.03-.035.028-.049-.002-.009-.012-.022-.031-.024-1.313-.025-2.722.155-3.244.283Z"
      fill="#919EAB"
    />
  </svg>
)

export default SvgComponent
