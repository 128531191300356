/* eslint-disable react/prop-types */
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
// routes
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

function GuestGuard({ children, isLoggedIn }) {

  if (isLoggedIn) {
    return <Navigate to={PATH_DASHBOARD.general.dashboard} />;
  }

  return <>{children}</>;
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn
})

export default connect(mapStateToProps)(GuestGuard)