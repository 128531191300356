import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { dispatch } from '../store';

const initialState = {
  salesList: {
    next: 1,
    previous: null,
    current_page: 1,
    total_pages: 1,
    count: 5,
    order_by: '-updated_at',
    query: '',
    results: [],
  },
  winnerList: {
    next: 1,
    previous: null,
    current_page: 1,
    total_pages: 1,
    order_by: '-updated_at',
    query: '',
    count: 5,
    results: [],
  },
  priceList: {
    next: 1,
    previous: null,
    current_page: 1,
    total_pages: 1,
    order_by: '-updated_at',
    query: '',
    count: 5,
    results: [],
  },
  ticketList: {
    tpid: 1,
    query: '',
    results: [],
  },
  timePeriod: {
    data: '',
    results: [],
  },
  prizeList: [],
  //   singlePrize:{

  //   }
};

export const fourDSlice = createSlice({
  name: 'fourD',
  initialState,
  reducers: {
    setSalesListResult: (state, action) => {
      state.salesList.results = action.payload.results;
    },
    setWinnerListResult: (state, action) => {
      state.winnerList.results = action.payload.results;
    },
    setTicketListResult: (state, action) => {
      state.ticketList.results = action.payload.results;
    },
    setTimePeriodResult: (state, action) => {
      state.timePeriod.results = action.payload;
    },
    setPrizeListResult: (state, action) => {
      state.prizeList = action.payload.results;
    },
  },
});

// Export
export const { setSalesListResult, setWinnerListResult, setTicketListResult, setTimePeriodResult, setPrizeListResult } =
  fourDSlice.actions;
export default fourDSlice.reducer;

export const handleGettingSalesList = async (next, date, query, order_by , moreList) => {
  if (next) {
    var date = moment().format('YYYY-MM-DD');
    try {
      const url = `/4d/bet-order-list/?page=${next}&date=${date}&page=1&page_size=${moreList}&query=${query}&order_by=${order_by}&status=`;
      const res = await axios.get(url);
      if (res.status === 200) {
        const data = res.data;
        dispatch(setSalesListResult(data));
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
};

export const handleGettingWinnerList = async (next = 1, query = '', order_by = '-updated_at', date) => {
  try {
    const url = `/4d/bet-order-list/?page=${next}&date=${date}&query=${query}&page=1&page_size=1000&order_by=${order_by}&status=Win`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setWinnerListResult(data));
    }
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const handleGettingTicketList = async (tpid) => {
  try {
    const url = `/4d/ticket-list?&query=&tpid=${tpid}&page_size=1000`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setTicketListResult(data));
    }
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const handleGettingTimePeriod = async (date) => {
  try {
    const url = `/4d/time-period-list?date=${date}&page_size=1000`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setTimePeriodResult(data));
    }
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const handleGettingPrizeList = async () => {
  try {
    const url = `/4d/prize-list?page_size=1000`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const data = res.data;
      dispatch(setPrizeListResult(data));
    }
    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};
