// routes
// components
import { PATH_DASHBOARD } from '../../../routes/paths';
import DashboardIcon from '../../../assets/icon_dashboard';
import SettingIcon from '../../../assets/icon_setting';
import TwoDIcon from '../../../assets/icon_2d';
import ThreeDIcon from '../../../assets/icon_3d';
import FourDIcon from '../../../assets/icon_4d';
import ThaiIcon from '../../../assets/icon_thai';
import SingaporeIcon from '../../../assets/icon_singapore';
import WalletIcon from '../../../assets/icon_wallet';
import SwapIcon from '../../../assets/icon_swap';
import PlayerWalletIcon from '../../../assets/icon_playerWallet';
import DocIcon from '../../../assets/icon_doc';
import AgentIcon from '../../../assets/icon_agent';
import PlayerIcon from '../../../assets/icon_player';
import Label from '../../../components/common/Label';

import DashboardIconActive from '../../../assets/icon_dashboard_active';
import SettingIconActive from '../../../assets/icon_setting_active';
import TwoDIconActive from '../../../assets/icon_2d_active';
import ThreeDIconActive from '../../../assets/icon_3d_active';
import FourDIconActive from '../../../assets/icon_4d_active';
import ThaiIconActive from '../../../assets/icon_thai_active';
import SingaporeIconActive from '../../../assets/icon_singapore_active';
import WalletIconActive from '../../../assets/icon_wallet_active';
import SwapIconActive from '../../../assets/icon_swap_active';
import PlayerWalletIconActive from '../../../assets/icon_wallet_active';
import DocIconActive from '../../../assets/icon_doc_active';
import AgentIconActive from '../../../assets/icon_agent_active';
import PlayerIconActive from '../../../assets/icon_player_active';

const navConfig = [
  // GENERAL
  {
    subheader: 'General',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.dashboard,
        icon: <DashboardIcon />,
        activeIcon: <DashboardIconActive />,
      },
      {
        title: 'Setting',
        path: PATH_DASHBOARD.general.setting,
        icon: <SettingIcon />,
        activeIcon: <SettingIconActive />,
      },
    ],
  },
  // MANAGEMENT
  {
    subheader: 'Management',
    items: [
      {
        title: 'TwoDManagement',
        path: PATH_DASHBOARD.TwoD.root,
        icon: <TwoDIcon />,
        activeIcon: <TwoDIconActive />,
        children: [
          { title: 'OddCard', path: PATH_DASHBOARD.TwoD.oddCard },
          { title: 'TimePeriod', path: PATH_DASHBOARD.TwoD.timePeriod },
          { title: 'SalesList', path: PATH_DASHBOARD.TwoD.salesList },
          { title: 'WinnerList', path: PATH_DASHBOARD.TwoD.winnerList },
          { title: 'Setting', path: PATH_DASHBOARD.TwoD.setting },
        ],
      },
      {
        title: 'ThreeDManagement',
        path: PATH_DASHBOARD.ThreeD.root,
        icon: <ThreeDIcon />,
        activeIcon: <ThreeDIconActive />,
        children: [
          { title: 'OddCard', path: PATH_DASHBOARD.ThreeD.oddCard },
          { title: 'TimePeriod', path: PATH_DASHBOARD.ThreeD.timePeriod },
          { title: 'SalesList', path: PATH_DASHBOARD.ThreeD.salesList },
          { title: 'WinnerList', path: PATH_DASHBOARD.ThreeD.winnerList },
          { title: 'Setting', path: PATH_DASHBOARD.ThreeD.setting },
        ],
      },
      {
        title: 'FourDManagement',
        path: PATH_DASHBOARD.FourD.root,
        icon: <FourDIcon />,
        activeIcon: <FourDIconActive />,
        children: [
          { title: 'OddCard', path: PATH_DASHBOARD.FourD.oddCard },
          { title: 'TimePeriod', path: PATH_DASHBOARD.FourD.timePeriod },
          { title: 'SalesList', path: PATH_DASHBOARD.FourD.salesList },
          { title: 'WinnerList', path: PATH_DASHBOARD.FourD.winnerList },
          { title: 'Setting', path: PATH_DASHBOARD.FourD.setting },
        ],
      },
      {
        title: 'ThaiLottery',
        path: PATH_DASHBOARD.Thai.root,
        icon: <ThaiIcon />,
        activeIcon: <ThaiIconActive />,
        children: [
          { title: 'TicketList', path: PATH_DASHBOARD.Thai.ticketList },
          { title: 'TimePeriod', path: PATH_DASHBOARD.Thai.timePeriod },
          { title: 'SalesList', path: PATH_DASHBOARD.Thai.salesList },
          { title: 'WinnerList', path: PATH_DASHBOARD.Thai.winnerList },
          { title: 'Setting', path: PATH_DASHBOARD.Thai.setting },
        ],
      },
      {
        title: 'SingaporeSweep',
        path: PATH_DASHBOARD.Singapore.root,
        icon: <SingaporeIcon />,
        activeIcon: <SingaporeIconActive />,
        children: [
          { title: 'TicketList', path: PATH_DASHBOARD.Singapore.ticketList },
          { title: 'TimePeriod', path: PATH_DASHBOARD.Singapore.timePeriod },
          { title: 'SalesList', path: PATH_DASHBOARD.Singapore.salesList },
          { title: 'WinnerList', path: PATH_DASHBOARD.Singapore.winnerList },
          { title: 'Setting', path: PATH_DASHBOARD.Singapore.setting },
        ],
      },
    ],
  },
  // ACCOUNT_MANAGEMENT
  {
    subheader: 'UserManagement',
    items: [
      {
        title: 'Agent',
        path: PATH_DASHBOARD.Agent.root,
        icon: <AgentIcon />,
        activeIcon: <AgentIconActive />,
        children: [
          { title: 'AgentList', path: PATH_DASHBOARD.Agent.agentList },
          { title: 'AgentCommission', path: PATH_DASHBOARD.Agent.badge },
        ],
      },
      {
        title: 'Player',
        path: PATH_DASHBOARD.Player.player,
        icon: <PlayerIcon />,
        activeIcon: <PlayerIconActive />,
      },
    ],
  },
  // WALLET
  {
    subheader: 'FlowWalletManagement',
    items: [
      {
        title: 'FlowWallet',
        path: PATH_DASHBOARD.Wallet.list,
        icon: <WalletIcon />,
        activeIcon: <WalletIconActive />,
      },
      {
        title: 'AgentRequest',
        path: PATH_DASHBOARD.Wallet.checkout,
        icon: <SwapIcon />,
        activeIcon: <SwapIconActive />,
        notiLink: `wallet/agent/transition-list/?page=1&query=&page_size=1000&order_by=-updated_at&type=&status=pending`,
      },
      {
        title: 'PlayerRequest',
        path: PATH_DASHBOARD.Wallet.player,
        icon: <PlayerWalletIcon />,
        activeIcon: <PlayerWalletIconActive />,
        notiLink: `wallet/player/transition-list/?query=&order_by=-created_at&status=pending&type=&to_admin`,
      },
      {
        title: 'PaymentSettings',
        path: PATH_DASHBOARD.Wallet.payment,
        icon: <SettingIcon />,
        activeIcon: <SettingIconActive />,
      },
      {
        title: 'TransactionHistory',
        path: PATH_DASHBOARD.Wallet.transaction,
        icon: <DocIcon />,
        activeIcon: <DocIconActive />,
      },
    ],
  },
];

export default navConfig;
