import axios from '../../utils/axios';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { dispatch } from '../store';

const initialState = {
  salesList: {
    next: 1,
    previous: null,
    current_page: 1,
    total_pages: 1,
    count: 5,
    order_by: '-updated_at',
    query: '',
    results: [],
  },
  winnerList: {
    next: 1,
    previous: null,
    current_page: 1,
    total_pages: 1,
    order_by: '-updated_at',
    query: '',
    count: 5,
    results: [],
  },
};

export const twoDSlice = createSlice({
  name: 'twoD',
  initialState,
  reducers: {
    setSalesListResult: (state, action) => {
      state.salesList.results = action.payload.results;
    },
    setWinnerListResult: (state, action) => {
      state.winnerList.results = action.payload.results;
    },
  },
});

// Export
export const { setSalesListResult, setWinnerListResult } = twoDSlice.actions;
export default twoDSlice.reducer;

export const handleGettingSalesList = async (next, query, order_by) => {
  // if (parseInt(next) == 1) {
  //   dispatch(clearWinnerList());
  // }
  if (next) {
    var date = moment().format('YYYY-MM-DD');
    // var date = '2022-10-28';
    try {
      const url = `/2d/bet-order-list/?page=${next}&date=${date}&page=1&page_size=1000&query=${query}&order_by=${order_by}&status=`;
      const res = await axios.get(url);
      if (res.status === 200) {
        const data = res.data;
        dispatch(setSalesListResult(data));
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
};

// console.log(initialState.salesList);

export const handleGettingWinnerList = async (next, query, order_by) => {
  if (next) {
    var date = moment().format('YYYY-MM-DD');
    try {
      const url = `/2d/bet-order-list/?page=${next}&date=${date}&query=${query}&page=1&page_size=1000&order_by=${order_by}&status=Win`;
      const res = await axios.get(url);
      if (res.status === 200) {
        const data = res.data;
        dispatch(setWinnerListResult(data));
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
};
