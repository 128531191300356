
import axios from "axios";
import { Typography, IconButton } from "@mui/material";
import { capitalCase } from "change-case";
import CloseIcon from "@mui/icons-material/Close";
import NProgress from "nprogress";
import { API_URL } from "../config";

axios.defaults.baseURL = API_URL;

export const errorHandler = (
  enqueueSnackbar,
  closeSnackbar) => {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      let text = "";
      if (error.response) {
        if (error.response.data.detail) {
          enqueueSnackbar(error.response.data.detail, {
            variant: "error",
            action: (key) => (
              <IconButton size="small" onClick={() => closeSnackbar(key)}>
                <CloseIcon />
              </IconButton>
            ),
          });
        } else if (typeof error.response.data === "object") {
          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const key in error.response.data) {
            text =
              typeof error.response.data[key] === "string" ? (
                `${error.response.data[key]}\n`
              ) : (
                <>
                  {" "}
                  <Typography fontWeight={"bold"}>
                    {capitalCase(key)}
                  </Typography>{" "}
                  : {error.response.data[key][0]}{" "}
                </>
              );
            enqueueSnackbar(text, {
              variant: "error",
              action: (key) => (
                <IconButton size="small" onClick={() => closeSnackbar(key)}>
                  <CloseIcon />
                </IconButton>
              ),
            });
          }
        } else {
          enqueueSnackbar(error.response.statusText, {
            variant: "error",
            action: (key) => (
              <IconButton size="small" onClick={() => closeSnackbar(key)}>
                <CloseIcon />
              </IconButton>
            ),
          });
        }
      }

      return Promise.reject(error);
    }
  );
};

export const loadProgressBar = (instance = axios) => {
  let requestsCounter = 0;
  const calculatePercentage = (loaded, total) =>
    Math.floor(loaded * 1.0) / total;

  const setupStartProgress = () => {
    instance.interceptors.request.use((config) => {
      // eslint-disable-next-line no-plusplus
      requestsCounter++;
      NProgress.start();
      return config;
    });
  };

  const setupUpdateProgress = () => {
    const update = (e) =>
      NProgress.inc(calculatePercentage(e.loaded, e.total));
    instance.defaults.onDownloadProgress = update;
    instance.defaults.onUploadProgress = update;
  };

  const setupStopProgress = () => {
    const responseFunc = (response) => {
      // eslint-disable-next-line no-plusplus
      if (--requestsCounter === 0) {
        NProgress.done();
      }
      return response;
    };

    const errorFunc = (error) => {
      // eslint-disable-next-line no-plusplus
      if (--requestsCounter === 0) {
        NProgress.done();
      }
      return Promise.reject(error);
    };

    instance.interceptors.response.use(responseFunc, errorFunc);
  };

  setupStartProgress();
  setupUpdateProgress();
  setupStopProgress();
};

export default axios;
