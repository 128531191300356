import * as React from "react"

const DocIconActive = (props) => (
  <svg
    width={19}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M13.691 0H5.31C2.27 0 .5 1.78.5 4.83v10.33C.5 18.26 2.27 20 5.31 20h8.381c3.089 0 4.809-1.74 4.809-4.84V4.83C18.5 1.78 16.78 0 13.691 0Z"
      fill="#FF3939"
    />
    <path
      d="M5.58 13.74h7.84c.399.04.7.38.7.79 0 .4-.301.74-.7.78H5.58c-.3.04-.59-.11-.75-.36a.795.795 0 0 1 .75-1.21Zm7.84-4.56a.781.781 0 0 1 0 1.561H5.58a.78.78 0 0 1 0-1.562h7.84ZM8.569 4.65c.431 0 .781.35.781.78 0 .44-.35.79-.781.79H5.58a.78.78 0 0 1 0-1.56v-.01h2.989Z"
      fill="#FF3939"
    />
  </svg>
)

export default DocIconActive
