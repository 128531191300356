import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getUser } from '../redux/slices/auth';
import {
  AgentRequestPendingList,
  PlayerRequestPendingList,
} from '../redux/slices/wallet';
// } from '../redux/slices/wallet';

const firebaseConfig = {
  apiKey: 'AIzaSyCon38RCDABqN30zHYCUzelcVI3BiuV7Tk',
  authDomain: 'royal-lottery-4e870.firebaseapp.com',
  projectId: 'royal-lottery-4e870',
  storageBucket: 'royal-lottery-4e870.appspot.com',
  messagingSenderId: '133401824351',
  appId: '1:133401824351:web:9080548a1dbbb9728b5fea',
  measurementId: 'G-VB9M3VG7KT',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging();

const requestUserPermission = async () => {
  const permission = await Notification.requestPermission();

  if (permission === 'granted') {
    console.log('Authorization status: granted');
  }
};

const getFCMToken = async () => {
  try {
    const fcmtoken = localStorage.getItem('FCMToken');
    
    if (fcmtoken == null) {
      const fcmtoken = await getToken(messaging, {
        vapidKey: 'BJP50UvMtavy5WEtGFDY1LomN1J0e_vr2ItkabsfBs-s5Zx9Vmgh--TAkGaRIIBlgv4xCju-B3i9L47L1e1vjEc',
      });
      if (fcmtoken) {
        await axios.post('auth/firebase-token/', { token: fcmtoken });
        localStorage.setItem('FCMToken', fcmtoken);
      }
    }
  } catch (error) {
    console.log('🚀 ~ file: firebase-service.js ~ line 29 ~ getFCMToken ~ error', error);
  }
};

const onForegroundNotification = () => {
  onMessage(messaging, async (remoteMessage) => {
    console.log('🚀 ~ file: index.js:49 ~ onMessage ~ remoteMessage', remoteMessage);
    const alertNoti = JSON.parse(localStorage.getItem('alertNoti'));
    const data = JSON.parse(remoteMessage.data.data);
    const img = data.notification.image;
    const text = data.notification.body;

    if (data.type == 'checkout-transition') {
      AgentRequestPendingList();
      AgentRequestPendingListBadge()
    }

    if (data.type == 'new-wallet-transition') {
      PlayerRequestPendingList()
    }
    

    getUser();

    if (alertNoti) {
      new Notification(data.notification.title, { body: text, icon: img });
    }
  });
};

export const initAllNotiServices = async () => {
  requestUserPermission();
  getFCMToken();
  onForegroundNotification();
};
