import * as React from "react"

const ThreeDIconActive = (props) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#FF3939">
      <path
        opacity={0.4}
        d="M18.11 0H6.899C2.816 0 .5 2.316.5 6.398v11.199C.5 21.684 2.816 24 6.898 24h11.213c4.087 0 6.389-2.316 6.389-6.398V6.398C24.5 2.316 22.198 0 18.11 0Z"
      />
      <path d="M6.632 10.158h-1.81c.057-.82.357-1.477.9-1.974.544-.496 1.2-.745 1.964-.745.84 0 1.552.24 2.138.713.586.473.881 1.045.881 1.715 0 .731-.36 1.388-1.083 1.978.46.291.793.596 1.008.91.211.318.319.693.319 1.134 0 .84-.277 1.51-.835 2.016-.557.506-1.293.76-2.212.76-.853 0-1.556-.245-2.119-.727-.562-.483-.9-1.158-1.031-2.016h1.852c.22.853.642 1.28 1.27 1.28.314 0 .576-.113.792-.333.216-.225.323-.497.323-.825 0-.403-.13-.732-.389-.99-.257-.257-.595-.384-1.003-.384H7.27v-1.509h.197c.427 0 .769-.103 1.027-.314.257-.211.384-.488.384-.835 0-.314-.103-.58-.305-.801a.98.98 0 0 0-.745-.333c-.69-.005-1.088.422-1.195 1.28ZM12.632 7.331h3.145c1.345 0 2.424.422 3.244 1.27.82.849 1.228 1.965 1.228 3.352 0 1.392-.422 2.503-1.266 3.342-.843.84-1.968 1.257-3.375 1.257h-2.976v-9.22Zm2.001 1.744v5.695h.788c.816 0 1.486-.22 2.01-.665.526-.446.788-1.186.788-2.227 0-.604-.126-1.139-.38-1.603-.252-.464-.618-.783-1.1-.947a4.241 4.241 0 0 0-1.374-.248l-.732-.005Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default ThreeDIconActive