import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#929EAB">
      <path
        opacity={0.4}
        d="M18.11 0H6.899C2.816 0 .5 2.316.5 6.398v11.199C.5 21.684 2.816 24 6.898 24h11.213c4.087 0 6.389-2.316 6.389-6.398V6.398C24.5 2.316 22.198 0 18.11 0Z"
      />
      <path d="M10.363 8.25 9.125 9.605c-.652-.385-1.12-.577-1.406-.577a.764.764 0 0 0-.567.249.825.825 0 0 0-.24.59c0 .455.413.849 1.238 1.181.605.258 1.06.493 1.369.699.31.206.567.497.778.867.21.37.314.769.314 1.2 0 .792-.319 1.481-.956 2.063a3.232 3.232 0 0 1-2.264.876c-.957 0-1.899-.45-2.832-1.35l1.308-1.523c.586.604 1.135.904 1.645.904.24 0 .47-.107.69-.318.22-.211.328-.436.328-.67 0-.483-.507-.905-1.524-1.27-.581-.212-.994-.413-1.237-.596-.244-.183-.45-.455-.614-.806a2.537 2.537 0 0 1-.249-1.083c0-.82.267-1.486.802-1.997.534-.511 1.233-.764 2.09-.764 1.013.01 1.87.333 2.565.97ZM19.869 9.417l-1.322 1.238c-.277-.478-.619-.834-1.027-1.074a2.538 2.538 0 0 0-1.28-.356c-.721 0-1.345.272-1.88.82-.529.549-.796 1.196-.796 1.941 0 .792.267 1.463.797 2.011.53.549 1.172.825 1.922.825.408 0 .797-.103 1.176-.31.375-.206.68-.59.91-1.157h-2.185V11.56h4.257v1.195c0 .614-.211 1.27-.633 1.978-.422.703-.975 1.219-1.664 1.547a4.799 4.799 0 0 1-2.086.487c-1.275 0-2.349-.459-3.225-1.382-.877-.924-1.313-2.063-1.313-3.422 0-1.29.45-2.386 1.35-3.3.9-.914 1.988-1.369 3.253-1.369 1.632-.005 2.874.703 3.746 2.123Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgComponent