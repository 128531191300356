import numeral from 'numeral';
import { array } from 'prop-types';

export const reverseString = (num) => {
  return num.toString().split('').reverse().join('');
};

export const groupBy = function (data, key) {
  const groupBy = data.reduce((group, item) => {
    const key_ = item[key];
    group[key_] = group[key_] ?? [];
    group[key_].push(item);
    return group;
  }, []);

  return reverseObjectIntoArray(groupBy);
};

export const reverseObjectIntoArray = (obj) => {
  var array = [];

  for (var i in obj) {
    array.push(obj[i]);
  }

  array.reverse();

  return array;
};

export const fUsername = (first_name, last_name) => {
  return (
    first_name
      .replace(/[^a-zA-Z ]/g, '')
      .replace(/\s+/g, '_')
      .toLowerCase() +
    '_' +
    last_name
      .replace(/[^a-zA-Z ]/g, '')
      .replace(/\s+/g, '_')
      .toLowerCase()
  );
};

export const permutation = (permutation) => {
  var length = permutation.length,
    result = [],
    c = new Array(length).fill(0),
    i = 1,
    k,
    p,
    initVal = permutation.join('');

  while (i < length) {
    if (c[i] < i) {
      k = i % 2 && c[i];
      p = permutation[i];
      permutation[i] = permutation[k];
      permutation[k] = p;
      ++c[i];
      i = 1;
      var val = permutation.slice().join('');
      if (!result.includes(val) && initVal !== val) {
        result.push(val);
      }
    } else {
      c[i] = 0;
      ++i;
    }
  }
  return result;
};

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '0,000' : '0,000');
}
