import * as React from "react"

const TwoDIconActive = (props) => (
  <svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#FF3939">
      <path
        opacity={0.4}
        d="M18.11 0H6.899C2.816 0 .5 2.316.5 6.398v11.199C.5 21.684 2.816 24 6.898 24h11.213c4.087 0 6.389-2.316 6.389-6.398V6.398C24.5 2.316 22.198 0 18.11 0Z"
      />
      <path d="M8.52 14.967h2.555v1.674H4.817l3.056-3.886c.446-.572.76-1.04.952-1.407.188-.365.286-.707.286-1.026 0-.347-.113-.633-.342-.858a1.166 1.166 0 0 0-.853-.337c-.394 0-.68.14-.858.426-.178.286-.286.727-.324 1.331H4.747c-.005-1.087.314-1.926.956-2.521.642-.596 1.434-.891 2.381-.891.882 0 1.599.258 2.157.769.557.51.834 1.167.834 1.968 0 .783-.323 1.65-.975 2.597l-.534.778c-.211.315-.479.67-.802 1.074l-.244.31ZM12.636 7.416h3.145c1.346 0 2.424.422 3.244 1.27.82.848 1.228 1.964 1.228 3.351 0 1.393-.422 2.504-1.265 3.343-.844.839-1.97 1.256-3.375 1.256h-2.977v-9.22Zm1.997 1.743v5.696h.787c.816 0 1.486-.22 2.011-.666.525-.445.788-1.186.788-2.226 0-.605-.127-1.14-.38-1.604-.253-.464-.619-.782-1.101-.946a4.241 4.241 0 0 0-1.374-.249l-.731-.005Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default TwoDIconActive
