import * as React from "react"

const DashboardIconActive = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M14.075 0h3.386A2.549 2.549 0 0 1 20 2.56v3.415a2.549 2.549 0 0 1-2.539 2.56h-3.386a2.549 2.549 0 0 1-2.538-2.56V2.56A2.549 2.549 0 0 1 14.075 0Z"
      fill="#FF3939"
    />
    <path
      d="M5.924 11.466a2.549 2.549 0 0 1 2.539 2.56v3.414A2.55 2.55 0 0 1 5.924 20H2.54A2.55 2.55 0 0 1 0 17.44v-3.415a2.549 2.549 0 0 1 2.539-2.56h3.385Zm11.538 0A2.549 2.549 0 0 1 20 14.026v3.414A2.55 2.55 0 0 1 17.462 20h-3.386a2.55 2.55 0 0 1-2.539-2.56v-3.415a2.549 2.549 0 0 1 2.538-2.56h3.387ZM5.924 0a2.549 2.549 0 0 1 2.539 2.56v3.415a2.549 2.549 0 0 1-2.539 2.56H2.54A2.549 2.549 0 0 1 0 5.974V2.56A2.549 2.549 0 0 1 2.539 0h3.385Z"
      fill="#FF3939"
    />
  </svg>
)

export default DashboardIconActive