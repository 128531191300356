import * as React from "react"

const SwapIcon = (props) => (
  <svg
    width={21}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity={0.4}
      d="M5.206 9.89a.94.94 0 0 0-.934.948l-.258 4.58a1.2 1.2 0 0 0 1.192 1.207c.657 0 1.19-.54 1.19-1.208l-.257-4.579a.94.94 0 0 0-.933-.947Z"
      fill="#919EAB"
    />
    <path
      d="M6.48.673S6.212.398 6.046.278A1.371 1.371 0 0 0 5.212 0c-.333 0-.642.105-.893.302-.046.046-.24.22-.4.383C2.912 1.637 1.265 4.12.762 5.421c-.08.197-.251.696-.262.963 0 .254.056.499.17.73.161.29.413.523.71.65.206.082.823.21.835.21.675.128 1.773.196 2.985.196 1.155 0 2.207-.068 2.893-.173.012-.012.777-.139 1.04-.28.48-.254.779-.753.779-1.287v-.046c-.012-.348-.31-1.08-.32-1.08C9.087 4.074 7.52 1.65 6.48.674Z"
      fill="#919EAB"
    />
    <path
      opacity={0.4}
      d="M15.795 8.11a.94.94 0 0 0 .934-.948l.256-4.579c0-.668-.533-1.208-1.19-1.208a1.2 1.2 0 0 0-1.192 1.208l.258 4.579a.94.94 0 0 0 .934.948Z"
      fill="#919EAB"
    />
    <path
      d="M20.329 10.885a1.497 1.497 0 0 0-.71-.65c-.205-.082-.823-.209-.834-.209-.675-.128-1.773-.197-2.985-.197-1.155 0-2.207.069-2.893.173-.012.012-.777.14-1.04.28a1.463 1.463 0 0 0-.779 1.288v.047c.012.348.31 1.078.32 1.078.504 1.23 2.07 3.657 3.112 4.631 0 0 .268.276.434.395.24.186.537.279.835.279.332 0 .64-.105.892-.302.046-.046.24-.22.4-.382 1.006-.953 2.654-3.437 3.156-4.736.08-.197.252-.697.263-.963 0-.256-.056-.5-.171-.732Z"
      fill="#919EAB"
    />
  </svg>
)

export default SwapIcon
