import { useState } from 'react';
// @mui
import {
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  useTheme,
} from '@mui/material';
import useLocales from '../../hooks/useLocales';
// hooks

// ----------------------------------------------------------------------

export default function LanguageSwitcher() {
  const { allLangs, currentLang, onChangeLang } = useLocales();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(currentLang.value);

  return (
    <>
      <Button
        variant="text"
        onClick={() => {
          setOpen(true);
        }}
        size="small"
        sx={{}}
      >
        {allLangs.map((lang, index) => {
          return (
            <span
              key={index}
              style={{
                color: lang.value == selected ? theme.palette.primary.main : theme.palette.text.primary,
                padding: '0px 5px',
                borderRight: `${index !== allLangs.length - 1 ? '1px' : '0px'} solid rgb(0,0,0)`,
                height: '22px',
              }}
            >
              {lang.label}
            </span>
          );
        })}
      </Button>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle
          sx={{
            background: (theme) => theme.palette.primary.main,
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 1.5,
            paddingBottom: 1.5,
            color: 'white',
          }}
        >
          Choose Your Language
        </DialogTitle>
        <DialogContent
          sx={{
            padding: 2,
          }}
        >
          <FormControl>
            <RadioGroup
              value={selected}
              sx={{ minWidth: '300px', mt: 1.5 }}
              onChange={(event) => {
                setSelected(event.target.value);
              }}
            >
              {allLangs.map((lang, index) => {
                return <FormControlLabel key={index} value={lang.value} control={<Radio />} label={lang.label} />;
              })}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <Stack
          direction={'row'}
          sx={{
            padding: 2,
            paddingTop: 0,
          }}
        >
          <Button
            sx={{
              width: '50%',
              marginRight: '5px',
            }}
            variant="contained"
            onClick={() => {
              onChangeLang(selected);
              setOpen(false);
            }}
            color="primary"
          >
            Ok
          </Button>

          <Button
            onClick={() => {
              setOpen(!open);
            }}
            sx={{
              width: '50%',
              marginLeft: '5px',
            }}
            variant="outlined"
            color="warning"
          >
            Cancel
          </Button>
        </Stack>
      </Dialog>
    </>
  );
}
