import * as React from "react"

const WalletIcon = (props) => (
  <svg
    width={21}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.014 0c3.446 0 5.482 1.979 5.482 5.338v.037h-4.234c-1.97.004-3.567 1.56-3.57 3.482-.004 1.925 1.595 3.489 3.57 3.491H20.5v.306c0 3.36-2.036 5.346-5.483 5.346H5.984C2.536 18 .5 16.014.5 12.654V5.338C.5 1.978 2.536 0 5.984 0h9.03Zm-4.13 3.875H5.24a.756.756 0 0 0-.762.744.756.756 0 0 0 .762.748h5.65a.756.756 0 0 0 .762-.75.757.757 0 0 0-.769-.742Z"
      fill="#919EAB"
    />
    <path
      opacity={0.4}
      d="M14.537 9.297c.21.951 1.043 1.62 1.996 1.603h3.25a.726.726 0 0 0 .717-.734V7.635a.728.728 0 0 0-.718-.735h-3.326c-1.083.004-1.958.903-1.956 2.01 0 .13.013.26.037.387Z"
      fill="#919EAB"
    />
    <path d="M16.5 9.9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill="#919EAB" />
  </svg>
)

export default WalletIcon
